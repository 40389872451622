export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/a-propos": [5],
		"/accessibilite": [6],
		"/mentions-legales": [7],
		"/statistiques": [8],
		"/territoire/[slug]/ambition": [9,[2,3]],
		"/territoire/[slug]/ambition/edition": [10,[2,3]],
		"/territoire/[slug]/objectifs": [11,[2]],
		"/territoire/[slug]/ordres_de_grandeur": [12,[2]],
		"/territoire/[slug]/ordres_de_grandeur/edition": [13,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';